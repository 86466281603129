<template>
  <div class="container pb-5 pt-lg-5 pb-lg-9">
    <div class="row justify-content-center">
      <div class="col-lg-8 col-xl-6">
        <img class="img-fluid mb-4" :src="`https://papaimages.s3.ap-northeast-1.amazonaws.com/frontend/anniversary38.jpg?${makeid()}`" alt="歡慶38歲 重磅福利">
        <h2 class="fw-bold fs-5 mb-2">-歡慶38歲 重磅福利-</h2>
        <p class="fw-bold">※ 滿額贈品數量有限，送完為止。</p>
        <p class="fw-bold">抽獎送_活動辦法：</p>
        <ul class="mb-2">
          <li class="fw-bold">活動時間：2022/10/1-2022/11/30</li>
          <li class="fw-bold">抽獎資格：須於2022/10/1-2022/11/30 23:59前(以訂單付款完成時間為主)，單筆訂單購買Papawash官網商品滿$2,500<span class="fw-normal">(折扣後金額)</span>，並付款完成，即可享有一次抽獎機會，買越多抽獎機會越多。</li>
          <li>抽獎日期：<span class="fw-bold">2022/12/15</span></li>
          <li>中獎公告：獎項將於<span class="fw-bold">2022/12/15</span>隨機抽出，<span class="fw-bold">2022/12/16</span>公布於官方粉絲團，Papawash將於<span class="fw-bold">2022/12/16</span>以電話、<span class="fw-bold">e-mail</span>方式通知中獎者，恕不提供其他方式。</li>
        </ul>
        <p class="fw-bold">※ 注意事項：</p>
        <ul class="border-bottom border-dark pb-3">
          <li>烏來璞石麗緻溫泉[景緻湯屋泡湯券]詳細使用辦法，請以票券上說明為準。</li>
          <li>中獎者(頭獎、二獎、三獎)需依領獎流程辦理，請於<span class="fw-bold">2023年01月16日前</span>確實填寫完成領獎通知書及檢附證明資料傳真至02-8752-6189或Mail至papawash@uitc.com.tw；亦可拍照傳至官方LINE帳號或FB粉絲團 messenger私訊小編，若資料不齊或不符者，恕不接受處理，逾時視同放棄領獎，請中獎者多多留意。</li>
          <li>依財政部規定，中獎金額超過NT$1,000元，依法需繳交身分證影本；獎項超過新台幣1,000元含以上、未達20,000元者，無須於兌獎時繳交所得稅。</li>
          <li>中獎者(四獎)，點數採自動歸戶至中獎者會員帳號，請於<span class="fw-bold">2022年12月31日前</span>至Papawash官網會員中心查詢。</li>
          <li>頭獎10000點數及四獎200點數使用到期日為<span class="fw-bold">2024年3月31日止。</span></li>
          <li>
            點數抵用上限：
            <ul class="list-unstyled">
              <li>單筆訂單結帳金額500元以下，點數抵用上限100點。</li>
              <li>單筆訂單結帳金額501~1000元，點數抵用上限250點。</li>
              <li>單筆訂單結帳金額1001元以上，點數抵用上限為結帳金額之50%。</li>
            </ul>
          </li>
          <li>若有任何問題請洽詢Papawash客服專線電話：0809-080-038 或官方LINE帳號或FB粉絲團 messenger私訊小編，服務時間：週一至週五 9:00-12:00、13:30-18:00(國定例假日除外)。</li>
        </ul>
        <p>※ <span class="fw-bold">Papawash</span>保留修改/變更活動與獎項細節之權利。</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    makeid () {
      let result = ''
      const characters =
        'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
      const charactersLength = characters.length
      for (let i = 0; i < 5; i++) {
        result += characters.charAt(
          Math.floor(Math.random() * charactersLength)
        )
      }
      return result
    }
  }
}
</script>
